/* Main colors */
:root {
  --cyan: #00bece;
  --darkGrey: #516375;
  --grey: #c0c1c2;
  --lightGrey: #f2f3f4;
  --ultralightGrey: #f7f8f9;
  --red: #cf230a;
  --white: #ffffff;
  --black: #000000;
}

body {
  font-family: "Titillium Web", sans-serif !important;
  color: var(--grey);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.garzone-loader {
  fill: white;
  animation: draw 2s linear infinite alternate;
}

@keyframes draw {
  to {
    fill: #23b7c9;
  }
}

@-webkit-keyframes draw {
  to {
    fill: #23b7c9;
  }
}

.logoMobile {
  display: none;
}
.logoDesktop {
  display: initial;
}

.logo {
  height: 50px;
}

.loading {
  width: 100%;
  height: auto;
}

.footer {
  background-color: var(--darkGrey);
  color: var(--white);
}

.center {
  display: flex;
  justify-content: center;
}

.p-home {
  font-size: 15px;
  line-height: 20px;
}

.link {
  color: var(--cyan);
}

.link:hover {
  color: var(--cyan);
}

.title-big {
  font-size: 50px;
  font-weight: "semibold";
  line-height: 1.3;
  color: var(--darkGrey);
  margin-bottom: 10px;
}

.subtitle {
  font-size: 22px;
  font-weight: "semibold";
  line-height: 1.3;
  color: var(--grey);
}

.label {
  font-size: 28px;
  font-weight: "semibold";
  line-height: 1.3;
  color: var(--darkGrey);
}

.editBtn {
  margin-top: 10px !important;
  padding: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
  background-color: var(--white) !important;
  color: var(--cyan) !important;
  font-weight: "semibold";
}

.input-check {
  margin-bottom: 15px;
}

.input-error {
  margin-top: 5px;
  color: red;
  font-size: 12px;
}

.input-text {
  border-radius: 6px;
  border: 1px solid #cccccc;
  padding: 4px 11px;
  font-size: 15px !important;
  color: var(--darkGrey);
}

.divider {
  padding-bottom: 40px;
  border-bottom: 1px solid var(--lightGrey);
}

/*Menu*/

.logoHome {
  height: 50px;
  width: 200px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.main-menu {
  z-index: 100;
  height: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.08);
}

.nohover:hover {
  border-bottom-color: var(--white) !important;
}

.main-menu-items {
  margin-bottom: -6px;
}

.avatar-uploader > .ant-upload {
  width: 100%;
  height: 164px;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
  height: 164px !important;
  padding: 0;
}

.ant-form-item-label {
  text-align: left;
}

.reduce-font-size {
  font-size: 15px;
}

.panel-container {
  padding: 30px;
}

.price-container {
  background-color: var(--lightGrey);
  padding: 5px 8px;
  width: 150px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  color: var(--darkGrey);
}

.price-container-light {
  background-color: rgba(242, 243, 244, 0.5);
  padding: 5px 8px;
  width: 150px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  color: rgba(81, 99, 99, 0.5);
}

/*Menu hamburger*/

.menu-hamburger {
  width: 30px;
  align-self: center;
  margin-bottom: 5px;
  padding: 0;
  background-color: var(--darkGrey);
  border: none;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 100%;
  height: 2px;
  background-color: var(--white);
  margin: 6px 0;
  transition: cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.3s;
}

.change.bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 3px);
  transform: rotate(-45deg) translate(-4px, 3px);
}

.change.bar2 {
  opacity: 0;
}

.change.bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}

/* Login */

.login-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: url("./Assets/Immagini/background.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow-y: scroll;
  overflow-x: hidden;
}

.login-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.home-div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.login-benvenuto {
  text-align: center;
  font-size: 32px;
  font-weight: "semibold";
  color: var(--white);
}

.home-benvenuto {
  text-align: center;
  font-size: 32px;
  font-weight: "semibold";
  color: var(--white);
  margin-bottom: 10px;
}

.home-subtitle {
  margin-top: 0;
  text-align: center;
  font-size: 32px;
  font-weight: "semibold";
  color: var(--cyan);
  margin-bottom: 20px;
}

.home-comune-subtitle {
  margin-top: 0;
  text-align: center;
  font-size: 26px;
  font-weight: "semibold";
  color: var(--white);
  margin-bottom: 20px;
}

.login-dati {
  height: 100%;
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ant-result-icon {
  display: flex;
  justify-content: center;
}

.result-subtitle {
  display: flex;
  justify-content: center;
  color: var(--grey);
}

.done-icon {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: var(--cyan);
  color: var(--white) !important;
  font-size: 50px !important;
}

.done-extra {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  color: var(--grey);
}

/*Registrazione*/
.logo-registrazione-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
  align-items: center;
  height: 100px;
}

.logo-comune {
  margin-left: 20px;
  height: 100px;
}

.titolo-comune {
  height: 100px;
  display: flex;
  align-items: center;
  color: var(--white);
  font-size: 48px;
  font-weight: "semibold";
}

.title-medium {
  font-size: 32px;
  font-weight: "semibold";
  color: var(--darkGrey);
  line-height: 1.3;
}

.logo-registrazione {
  height: 45px;
}

.ant-popover-inner {
  border-radius: 6px;
}

.ant-popover-inner-content {
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--white);
}

.ant-popover-arrow {
  border-bottom-color: rgba(0, 0, 0, 0.7) !important;
  border-right-color: rgba(0, 0, 0, 0.7) !important;
  box-shadow: none !important;
}

.backToChats {
  display: none;
}

.chatList {
  overflow-x: hidden;
}

.orderStatus {
  display: initial;
}
.orderStatusPanel {
  display: none;
}

@media (min-width: 1128px) and (max-width: 1194px) {
  .reduce-font-size {
    font-size: 14px;
  }
}

@media (min-width: 1061px) and (max-width: 1128px) {
  .reduce-font-size {
    font-size: 13px;
  }
}

@media (min-width: 927px) and (max-width: 1061px) {
  .reduce-font-size {
    font-size: 11px;
  }
}

@media (min-width: 768px) and (max-width: 927px) {
  .reduce-font-size {
    font-size: 8px;
  }
}

@media (min-width: 768px) and (max-width: 879px) {
  .reduce-hashtags {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .logoMobile {
    display: initial;
  }
  .logoDesktop {
    display: none;
  }
  .main-menu {
    padding-left: 10px;
    padding-right: 3px;
  }

  .main-menu-items {
    width: 56px;
  }

  .header-sidebar-right {
    z-index: 100 !important;
  }

  .panel-container {
    padding: 16px;
  }

  .header-sidebar-right {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
  }

  .table-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .product-image {
    margin-bottom: 30px;
  }

  .title-medium {
    font-size: 30px;
  }

  .titolo-comune {
    height: 70px;
    font-size: 2rem;
  }

  .logo-registrazione-container {
    height: 70px;
  }

  .logo-comune {
    height: 70px;
  }
}

@media (min-width: 576px) {
  .input-col:not(.exclude-field) {
    margin-right: 40px;
  }
}

@media (max-width: 576px) {
  .ant-alert-description {
    font-size: 12px !important;
  }

  .subtitle {
    font-size: 18px;
  }

  .ant-layout-header {
    padding: 5px 10px;
  }

  .logo {
    width: 120px;
  }

  .ant-result {
    padding: 32px 15px;
  }

  .title-big {
    font-size: 28px;
  }

  .label {
    font-size: 24px;
  }

  .input-check {
    margin-bottom: 30px;
  }

  .title-medium {
    font-size: 26px;
  }

  .titolo-comune {
    height: 70px;
    font-size: 2rem;
    display: flex;
    justify-content: center;
  }

  .logo-registrazione-container {
    height: 70px;
  }

  .logo-registrazione {
    width: 70px;
    height: auto;
  }

  .logo-comune {
    height: 70px;
  }

  .home-benvenuto {
    font-size: 24px;
  }

  .home-subtitle {
    font-size: 24px;
  }

  .home-comune-subtitle {
    font-size: 20px;
  }

  .home-caption {
    display: none;
  }
}

@media (max-width: 1142px) {
  .chatList {
    left: 0 !important;
    width: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .chatPanel {
    display: none;
  }
  .chatDetails {
    display: none;
    width: 100% !important;
    background-color: white !important;
  }
  .backToChats {
    display: block;
  }
  .orderStatus {
    display: none;
  }
  .orderStatusPanel {
    display: initial;
  }
}

.ant-radio {
  vertical-align: middle;
}


.stripe-connect {
  background: #635bff;
  display: inline-block;
  height: 38px;
  text-decoration: none;
  width: 180px;

  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  -webkit-font-smoothing: antialiased;
}

.stripe-connect span {
  color: #ffffff;
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 14px;
  padding: 11px 0px 0px 24px;
  position: relative;
  text-align: left;
}

.stripe-connect:hover {
  background: var(--cyan);
}

.stripe-connect.white {
  background: #ffffff;
}

.stripe-connect.white span {
  color: #0a2540;
}

.stripe-connect.white:hover {
  background: var(--cyan);
  
}
.stripe-connect.white:hover > span {
color: var(--white);
}

.stripe-connect span::after {
  background-repeat: no-repeat;
  background-size: 49.58px;
  content: "";
  height: 20px;
  left: 62%;
  position: absolute;
  top: 28.95%;
  width: 49.58px;
}

/* Logos */
.stripe-connect span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

.stripe-connect.white span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 24.0.3, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%230A2540;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}
